import SkrDataController from './SkrDataController'

const typeFields = ['result_type']
const locationFields = ['continents', 'countries', 'regions']
const filterFields = ['trip_type', 'duration', 'availability', 'date_range']
const resultFields = filterFields.concat(locationFields, typeFields, ['custom_sorting', 'flags'])
const feedbackFields = locationFields.concat(['trip_type', 'duration', 'flags'])
const contactFields = locationFields;

// noinspection FunctionWithMoreThanThreeNegationsJS
export function getTeaserConfig(state) {
  const resultType = state["result_type"];
  const components = {}
  components.countries = {
    componentId: 'countries',
    dataField: 'countries.raw',
    filterLabel: 'Reiseziel',
    selectAllLabel:'- Reiseziel -',
    URLParams: false,
    searchFunnel: true,
    react: {and: filterFields},
  }

  components.trip_type = {
    componentId: 'trip_type',
    dataField: 'trip_type.raw',
    filterLabel: 'Art',
    selectAllLabel:'- Reisearten -',
    URLParams: false,
    searchFunnel: true,
    react: {and: filterFields.filter((val) => val !== 'trip_type')},
  }

  components.date_range = {
    componentId: 'date_range',
    dataField: resultType + ':departure',
    filterLabel: 'Reisedatum',
    URLParams: false,
    searchFunnel: true,
    placeholder: {start: 'Abreisedatum von', end: 'Abreisedatum bis'},
    customQuery: (value, props) => {
      if (!value || (value && (!value.start || !value.end))) return null
      const query = {'range': {'departure': {'gte': value.start, 'lte': value.end}}}
      const [result_type] = props.dataField.split(':')
      if (result_type !== 'departure') {
        return {'query': {'has_child': {'type': 'departure', 'query': {'bool': {'must': [query]}}}}}
      }
      return {'query': query}
    },
    innerClass: {
      'title': 'acts-as-label',
      'input-container': 'date-range-input-container',
      'daypicker-overlay-wrapper': 'date-range-overlay-wrapper DayPickerInput-OverlayWrapper',
    },
  }

  components.availability = {
    componentId: 'availability',
    dataField: resultType + ':availability',
    filterLabel: 'Verfügbarkeit',
    selectAllLabel: 'nur buchbare Reisen anzeigen',
    showFilter: false,
    URLParams: false,
    searchFunnel: true,
    data: [
      {'status': 0, 'label': 'alle Reisen anzeigen'}],
    customQuery: (value, props) => {
      const availabilityValue = value == null ? 1 : value.status;
      if (availabilityValue === 0) return null
      const query = {'match': {'availability': availabilityValue}};
      const [result_type] = props.dataField.split(':')
      if (result_type !== 'departure') {
        return {'has_child': {'type': 'departure', 'query': {'bool': {'must': [query]}}}}
      }
      return query
    },
    innerClass: {'title': 'acts-as-label', 'select': 'acts-as-select', 'daypicker-overlay-wrapper': 'date-range-overlay'},
  }

  components.result_type = {
    componentId: 'result_type',
    dataField: 'type',
    URLParams: false,
    customQuery: (value, props) => {
      return {'match': {'type': 'trip'}}
    },
  }

  components.result_count = {
    componentId: 'result_count',
    react: {and: resultFields},
    resultType: resultType,
    size: 0
  }

  components.search_funnel = {
    componentId: 'search_funnel',
    searchUrl: '/reisefinder/'
  }

  return components
}

// noinspection FunctionWithMoreThanThreeNegationsJS
export function getComponentConfig(state, type = 'search') {
  const resultType = state["result_type"];
  const components = getTeaserConfig(state)
  delete components.result_count;

  components.continents = {
    componentId: 'continents',
    dataField: 'continents.raw',
    filterLabel: 'Kontinent',
    title: 'Kontinent',
    URLParams: type === 'search',
    searchFunnel: type === 'landingpage',
    showFilter: type === 'search',
    react: {and: filterFields.concat(typeFields)},
  }

  delete components.countries.selectAllLabel
  components.countries = {
    ...components.countries,
    title: 'Reiseziel',
    URLParams: type === 'search',
    searchFunnel: type === 'landingpage',
    showFilter: type === 'search',
    react: {and: filterFields.concat(typeFields, ['continents'])}
  }

  components.regions = {
    componentId: 'regions',
    dataField: 'regions.raw',
    filterLabel: 'Region',
    title: 'Region',
    URLParams: type === 'search',
    searchFunnel: type === 'landingpage',
    showFilter: type === 'search',
    react: {and: filterFields.concat(typeFields, ['continents', 'countries'])},
  }

  components.flags = {
    componentId: 'flags',
    filterLabel: 'Kennzeichnung',
    title: 'Kennzeichnung',
    URLParams: type === 'search',
    searchFunnel: type === 'landingpage',
    showFilter: type === 'search',
    customQuery: (value, props) => {
      if (!value) return null
      return {term: { "flags.raw" : value}}
    }
  }

  components.trip_type = {
    componentId: 'trip_type',
    dataField: 'trip_type.raw',
    filterLabel: 'Art',
    title: 'Art',
    URLParams: type === 'search',
    searchFunnel: type === 'landingpage',
    react: {and: filterFields.concat(typeFields).filter((val) => val !== 'trip_type')},
  }

  components.date_range = {
    ...components.date_range,
    title: 'Abreise-Zeitraum',
    URLParams: type === 'search',
    searchFunnel: type === 'landingpage',
  }

  components.duration = {
    componentId: 'duration',
    dataField: resultType + ':duration',
    filterLabel: 'Reisedauer',
    title: 'Dauer',
    selectAllLabel: 'Alle',
    showFilter: type === 'search',
    URLParams: type === 'search',
    searchFunnel: type === 'landingpage',
    data: [
      {
        'start': 0, 'end': 8, 'label': 'bis 8 Tage',
      }, {
        'start': 9, 'end': 15, 'label': '9 bis 15 Tage',
      }, {
        'start': 16, 'end': 1000, 'label': 'über 15 Tage',
      }],
    customQuery: (value, props) => {
      if (!value || (value && !value.start && !value.end)) return null
      const query = {'range': {'duration': {'gte': value.start, 'lte': value.end}}}
      const [result_type] = props.dataField.split(':')
      if (result_type !== 'departure') {
        return {'has_child': {'type': 'departure', 'query': {'bool': {'must': [query]}}}}
      }
      return query
    },
    react: {and: resultFields.filter((val) => val !== 'duration')},
    innerClass: {'title': 'acts-as-label', 'select': 'acts-as-select'},
  }

  components.availability = {
    ...components.availability,
    title: 'Verfügbarkeit',
    selectAllLabel: 'nur buchbare Reisen anzeigen',
    URLParams: type === 'search',
    searchFunnel: type === 'landingpage',
    showFilter: type === 'search',
  }

  components.result_type = {
    ...components.result_type,
    multiSelect: false,
    defaultSelected: '',
    title: 'Ansicht',
    URLParams: type === 'search',
    searchFunnel: type === 'landingpage',
    data: [{'label': 'Details', 'value': ''}, {'label': 'Terminliste', 'value': 'departure'}],
    customQuery: (value, props) => {
      if (value[0] && value[0].value === 'departure') {
        return {'match': {'type': 'departure'}}
      }
      return {'match': {'type': 'trip'}}
    },
    innerClass: {'title': 'acts-as-label'},
  }

  components.custom_sorting = {
    componentId: 'custom_sorting',
    dataField: resultType !== 'departure' ? '_score' : 'departure',
    customQuery: (value, props) => {
      if (props.dataField === 'departure') {
        return SkrDataController.defaultQuery()
      }

      return {
        'function_score': {
          'functions': [
            {
              'script_score': {
                'script': {
                  'source': 'doc[\'duration\'].empty ? 0 : Math.max(100 - doc[\'duration\'].value, 0)',
                },
              }, 'weight': 1,
            }, {
              'filter': {'match': {'is_bestseller': true}}, 'weight': 100,
            }, {
              'filter': {
                'has_child': {
                  'type': 'departure', 'query': {'bool': {'must': [{'range': {'availability': {'gte': 1}}}]}},
                },
              }, 'weight': 100,
            }], 'score_mode': 'sum',
        },
      }
    },
  }

  components.results = {
    className: type === 'search' ? 'grid__column is-small-12' + (resultType !== 'departure' ? ' is-medium-8' : '') : null,
    componentId: 'results',
    dataField: resultType !== 'departure' ? '_score' : 'departure',
    sortBy: resultType !== 'departure' ? 'desc' : 'asc',
    react: {and: resultFields},
    resultType: resultType,
    vacancy: state.vacancy,
    onNoResults: type === 'search' || type === 'landingpage'  ? 'Keine Ergebnisse gefunden.' : '',
    onResultStats: type === 'search'
      ? (total, time, props) => (<p data-num-found>Hier sehen Sie {total} {props.resultType === 'departure' ? 'Reisetermine' : 'Reisen'}</p>)
      : (total, time, props) => { return  },
    onQueryChange: (prevQuery, nextQuery) => {
      if (typeof nextQuery['query']['bool']['must'][0]['bool']['must'] !== 'undefined') {
        const hasChildQueriesByType = {}
        const mustQueries = []
        nextQuery['query']['bool']['must'][0]['bool']['must'].forEach((query) => {
          if(typeof query['has_child'] === 'object' && typeof query['has_child']['type'] !== 'undefined') {
            if (typeof hasChildQueriesByType[query['has_child']['type']] === 'undefined') {
              hasChildQueriesByType[query['has_child']['type']] = []
            }
            hasChildQueriesByType[query['has_child']['type']].push(query)
          } else {
            mustQueries.push(query)
          }
        })

        if (Object.keys(hasChildQueriesByType).length > 0) {
          for (let type in hasChildQueriesByType) {
            let childQueries =  hasChildQueriesByType[type].map((query) => (query['has_child']['query']))
            let hasChildQuery = {
              'has_child': {
                'type': type,
                'query': {
                  'bool': {
                    'must': childQueries
                  }
                }
              }
            }
            // console.log(hasChildQuery['has_child']['query']['bool']['must'])
            mustQueries.push(hasChildQuery)
          }
        }
        // console.log(mustQueries)
        nextQuery['query']['bool']['must'][0]['bool']['must'] = mustQueries
      }

      return nextQuery;
    }
  }

  if (type === 'search') {
    components.feedback_aggregate = {
      componentId: 'feedback_aggregate',
      react: {and: feedbackFields}
    }
  }

  if (type === 'search' || type === 'landingpage') {
    components.date_range = {
      ...components.date_range,
      placeholder: {start: 'von', end: 'bis'},
    }
  }

  components.feedbacks = {
    componentId: 'feedbacks',
    react: {and: feedbackFields},
  }

  components.contact = {
    componentId: 'contact',
    react: {and: contactFields},
  }

  if (type === 'search') {
    delete components.search_funnel;
  } else {
    components.search_funnel.transitionOnParamChange = true
  }

  return components
}
